import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { flattenNavigators } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Text } from '@wh-components/core/Text/Text'
import { useOptimizelyDecision, useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import icon from './icon_savesize_cyanrose.svg'

const LOCAL_STORAGE_KEY = 'save_size_fake_door_seen'

interface SaveSizeFakeDoorProps extends BoxProps {
    slot: 'filter' | 'srlDesktop' | 'srlMobile'
    searchResult: SearchResult
}

export const SaveSizeFakeDoor: FunctionComponent<SaveSizeFakeDoorProps> = ({ slot, searchResult, ...restProps }) => {
    const [seen, setSeen] = useState(true)

    useEffect(() => {
        setSeen(window.localStorage.getItem(LOCAL_STORAGE_KEY) === 'true')
    }, [])

    if (seen) {
        return null
    }

    const breadcrumbTitles = searchResult.breadcrumbs?.map((breadcrumb) => breadcrumb.displayName) ?? []
    const isFashionOrBaby = breadcrumbTitles.includes('Mode / Accessoires') || breadcrumbTitles.includes('Baby / Kind')
    const hasSizeNavigator = flattenNavigators(searchResult.navigatorGroups)
        .flatMap((navigator) => navigator.metaTags)
        .includes('SAVE_PREFERENCE')

    if (!isFashionOrBaby || !hasSizeNavigator) {
        return null
    }

    return <Experiment slot={slot} {...restProps} />
}

interface Experiment extends BoxProps {
    slot: 'filter' | 'srlDesktop' | 'srlMobile'
}

export const Experiment: FunctionComponent<Experiment> = ({ slot, ...restProps }) => {
    const [open, setOpen] = useState(false)

    const variation = useOptimizelyDecision('bbx_save_size_fake_door')
    const trackEvent = useOptimizelyTrack()

    const handleOpen = () => {
        setOpen(true)
        trackEvent('bbx_save_size_fake_door_btn_interaction')
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSeen = () => {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
    }

    if (
        !variation.enabled ||
        (variation.variationKey === 'srl' && slot === 'filter') ||
        (variation.variationKey === 'filter' && (slot === 'srlDesktop' || slot === 'srlMobile'))
    ) {
        return null
    }

    return (
        <Fragment>
            <BoxContent slot={slot} onOpen={handleOpen} onSeen={handleSeen} {...restProps} />
            <Modal
                header="Deine Größen immer gemerkt!"
                footer={
                    <Box justifyContent="flex-end" display="flex">
                        <Button onClick={handleClose}>Alles Klar</Button>
                    </Box>
                }
                isOpen={open}
                onRequestClose={handleClose}
            >
                Danke für dein Interesse. Aktuell arbeiten wir gerade an diesem Feature, schon bald siehst du mehr!
            </Modal>
        </Fragment>
    )
}

interface BoxContentProps extends BoxProps {
    slot: 'filter' | 'srlDesktop' | 'srlMobile'
    onOpen: () => void
    onSeen: () => void
}

const BoxContent: FunctionComponent<BoxContentProps> = ({ slot, onOpen, onSeen, ...restProps }) => {
    const heading = 'Deine Größen immer gemerkt!'
    const content = 'Du möchtest deine Größen abspeichern, um das nicht jedes Mal aufs Neue tun zu müssen?'
    const buttonText = 'Zu meinen Größen'

    const commonProps: BoxProps = {
        backgroundColor: 'alert.info.background',
        paddingHorizontal: 'm',
        paddingVertical: 's',
        borderRadius: 'm',
        flexWrap: 'wrap',
        alignItems: 'center',
        position: 'relative',
        ...restProps,
    }

    switch (slot) {
        case 'filter':
            return (
                <Fragment>
                    <Box display={{ phone: 'flex', tablet: 'none' }} {...commonProps}>
                        <Box flex="0 0 60px" display="block">
                            <img src={icon} aria-hidden="true" width="60px" height="60px" />
                        </Box>
                        <Box flex="1 1" paddingBottom="s">
                            <Text as="div" fontWeight="bold" paddingBottom="s">
                                {heading}
                            </Text>
                            <Text as="div">{content}</Text>
                        </Box>
                        <Box flex="1 0 100%">
                            <Button width="100%" onClick={onOpen}>
                                {buttonText}
                            </Button>
                        </Box>
                        <IntersectionTrigger onSeen={onSeen} />
                    </Box>
                    <Box display={{ phone: 'none', tablet: 'block' }} {...commonProps}>
                        <img src={icon} aria-hidden="true" width="45px" height="45px" style={{ float: 'right' }} />
                        <Box flex="1 1" paddingBottom="s">
                            <Text as="div" fontWeight="bold" paddingBottom="s">
                                {heading}
                            </Text>
                            <Text as="div">{content}</Text>
                        </Box>
                        <Box flex="1 0 100%">
                            <Button width="100%" onClick={onOpen}>
                                {buttonText}
                            </Button>
                        </Box>
                        <IntersectionTrigger onSeen={onSeen} />
                    </Box>
                </Fragment>
            )
        case 'srlMobile':
            return (
                <Box display={{ phone: 'flex', tablet: 'none' }} {...commonProps}>
                    <Box flex="0 0 60px" display="block">
                        <img src={icon} aria-hidden="true" width="60px" height="60px" />
                    </Box>
                    <Box flex="1 1" paddingBottom="s">
                        <Text as="div" fontWeight="bold" paddingBottom="s">
                            {heading}
                        </Text>
                        <Text as="div">{content}</Text>
                    </Box>
                    <Box flex="1 0 100%">
                        <Button width="100%" onClick={onOpen}>
                            {buttonText}
                        </Button>
                    </Box>
                    <IntersectionTrigger onSeen={onSeen} />
                </Box>
            )
        case 'srlDesktop':
            return (
                <Box display={{ phone: 'none', tablet: 'flex' }} {...commonProps}>
                    <Box flex="0 0 60px" display="block">
                        <img src={icon} aria-hidden="true" width="60px" height="60px" />
                    </Box>
                    <Box flex="1 0 " paddingBottom="s">
                        <Text as="div" fontWeight="bold" paddingBottom="s">
                            {heading}
                        </Text>
                        <Text as="div">{content}</Text>
                    </Box>
                    <Box flex="0 1" marginLeft="m">
                        <Button onClick={onOpen}>{buttonText}</Button>
                    </Box>
                    <IntersectionTrigger onSeen={onSeen} />
                </Box>
            )
    }
}

interface IntersectionTriggerProps {
    onSeen: () => void
}

const IntersectionTrigger: FunctionComponent<IntersectionTriggerProps> = ({ onSeen }) => {
    const ref = useRef(null)

    useIntersectionObserver(ref, { triggerOnce: true }, [], onSeen)

    return <Box ref={ref} position="absolute" bottom="0" left="0" width="1px" height="1px" />
}
